<script setup>
import {useBaseStore} from "~/stores/base";

/** props **/
const props = defineProps({})

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** methods **/
</script>

<template>
  <div class="module-carousel-count-6-skeleton-mobile" v-if="isMobile">
    <div class="title">{{ 'special collection' }}</div>
    <div class="swiper">
      <div class="swiper-item" v-for="item in 6">
        <div class="cover-image-skeleton"></div>
        <div class="item-bottom">
          <div class="skeleton-loader" style="width: 65%"></div> <!-- Third skeleton loader -->
          <div class="skeleton-loader"></div> <!-- Third skeleton loader -->
          <div class="skeleton-loader"></div> <!-- Third skeleton loader -->
        </div>
      </div>
    </div>
  </div>
  <div class="module-carousel-count-6-skeleton-desktop" v-else>
    <div class="title">{{ 'special collection' }}</div>
    <div class="swiper">
      <div class="swiper-item" v-for="item in 6">
        <div class="cover-image-skeleton"></div>
        <div class="item-bottom">
          <div class="skeleton-loader" style="width: 120px"></div> <!-- Third skeleton loader -->
          <div class="skeleton-loader"></div> <!-- Third skeleton loader -->
          <div class="skeleton-loader"></div> <!-- Third skeleton loader -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Define loader colors as variables */
:root {
  --loader-background-color: #EEEEEE;
  --loader-highlight-color: #DEDEDE;
}
</style>

<style scoped lang="scss">
@import "src/assets/config";

.module-carousel-count-6-skeleton-mobile {
  padding: 10px 16px 10px 16px;
  margin-top: 16px;

  .title {
    /* Headline/small */
    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;
    text-align: center;
  }

  .swiper {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 10px;

    .swiper-item {
      flex-shrink: 0;
      margin-right: 10px;

      .cover-image-skeleton {
        width: 37.3vw;
        height: 52.3vw;
        background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
        background-size: 200% 100%;
        animation: loading 2s infinite ease-in-out;
      }

      .item-bottom {
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .skeleton-loader {
          width: 100%;
          height: 18.5px;
          background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
          background-size: 200% 100%;
          animation: loading 2s infinite ease-in-out;
        }
      }
    }
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}

.module-carousel-count-6-skeleton-desktop {
  margin-top: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: #fff;
  text-align: center;

  .title {
    padding-top: 18px;
    width: 100%;
    min-width: $content-1280-width;
    text-align: center;

    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;
  }

  .swiper {
    width: $content-1280-width;
    margin-top: 10px;
    display: flex;
    gap: 10px;

    .swiper-item {

      .cover-image-skeleton {
        width: 205px;
        height: 287px;
        background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
        background-size: 200% 100%;
        animation: loading 2s infinite ease-in-out;
      }

      .item-bottom {
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .skeleton-loader {
          width: 100%;
          height: 18.5px;
          background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
          background-size: 200% 100%;
          animation: loading 2s infinite ease-in-out;
        }
      }
    }
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}
</style>
