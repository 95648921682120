<script setup>
import {useBaseStore} from "~/stores/base";

const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits(["close", "success"])

/** props **/
const props = defineProps({
  title: {
    required: true,
    default: ""
  },
  description: {
    required: true,
    default: ""
  },
})

/** data **/

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** watch **/

/** methods **/
const handleCloseClick = () => {
  emits("close")
}

/** lifecycle **/

</script>

<template>
  <div class="module-streamer-text-mobile" v-if="isMobile">
    <div class="button-close" @click="handleCloseClick">
      close
      <img class="close" src="@/assets/img/icon-close.svg" alt="close">
    </div>
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
  <div class="module-streamer-text-desktop" v-else>
    <div class="button-close" @click="handleCloseClick">
      close
      <img class="close" src="@/assets/img/icon-close.svg" alt="close">
    </div>
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-streamer-text-mobile {
  width: 100%;
  background-color: $color-gray-800;
  color: white;

  padding: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .button-close {
    cursor: pointer;
    align-self: flex-end;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .title {
    color: #FFF;
    font-family: "Alternate Gothic No3 D";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 88.889% */
    text-transform: uppercase;
    padding-top: 8px;
  }

  .description {
    color: #FFF;
    /* Text sm/Regular */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
  }
}

.module-streamer-text-desktop {
  min-width: $content-1280-width;
  width: 100%;
  background-color: $color-gray-800;
  color: white;

  padding: 24px 30px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .button-close {
    position: absolute;
    right: 30px;
    top: 24px;

    font-family: Druk Wide Cy;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-decoration: underline;

    display: flex;
    align-items: center;

    cursor: pointer;

    .close {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  .title {
    color: #FFF;
    font-family: "Alternate Gothic No3 D";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 88.889% */
    text-transform: uppercase;
    padding-top: 8px;
  }

  .description {
    color: #FFF;
    /* Text sm/Regular */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
  }
}
</style>
