import {defineStore} from "pinia";

export const useCartStore = defineStore("cart", {
  state: () => ({
    topInfo: {
      alarm: [],
      deliveryTrackingNo: "",
      deliveryTrackingUrl: "",
      orderId: "",
      purchasedNum: "",
      rentalTime: "",
      returnLabelUrl: "",
      returnTrackingNo: "",
      returnTrackingUrl: "",
      status: ""
    },
    cartItemList: []
  }),
  getters: {
    getterTopInfo(state) {
      return state.topInfo
    },
    getterCartItemList(state) {
      return state.cartItemList
    },
  },
  actions: {
    updateTopInfo(topInfo) {
      this.topInfo = topInfo
    },
    updateCartItemList(cartItemList) {
      this.cartItemList = cartItemList
    },
  },
})
