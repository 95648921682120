<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleStreamerCoupon from "~/components/Streamer/ModuleStreamerCoupon.vue";
import ModuleStreamerWaitlist from "~/components/Streamer/ModuleStreamerWaitlist.vue";
import {getStreamerStatusLocalStorage, setStreamerStatusLocalStorage, sleep} from "~/utils/common";
import ModuleStreamerInvitation from "~/components/Streamer/ModuleStreamerInvitation.vue";
import ModuleStreamerText from "~/components/Streamer/ModuleStreamerText.vue";

const visibleStreamerName = ref("")
// todo 调整命名为 CUSTOM_STREAMER_NAME，排除WILDFIRE
// todo 增加rule到服务端数据结构中，而不是写死规则，只要不写死该规则，则不需要在此MAP中陈列name
const STREAMER_NAME = {
  WILDFIRE: "wildfire", // textPop
  HOME_PAGE_POP: "homePagePop", // customPop
  WAITLIST_POP: "waitlistPop", // customPop
  INVITATION_POP: "invitationPop", // customPop
}

const visibleStreamerType = ref("")
const STREAMER_TYPE = {
  TEXT: "textPop",
  CUSTOM: "customPop"
}

const visibleStreamerTitle = ref("")
const visibleStreamerSubtitle = ref("")
const visibleStreamerDescription = ref("")
const visibleStreamerPic = ref({})
const visibleStreamerBtnText = ref("")

const visibleStreamerDelay = ref(0)

const streamerMobile = ref(null)
const streamerDesktop = ref(null)
const placeholder = ref(null)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const appInit = computed(() => useBaseStore().getterAppInit)
const isLogin = computed(() => useBaseStore().getterIsLogin)
const userInfo = computed(() => useBaseStore().getterUserInfo)

watch(isMobile, () => {
  placeholder.value.style.height = '0px'  // 复原占位符高度为0
//   animateSlideDown()
})

const streamerInit = () => {
  // todo mock
  const mock = {
    "popList": {
      "priority": ["homePagePop", "wildfire"],
      "data": {
        "homePagePop": {
          "type": "customPop",            // 弹窗类型
          "title": "Temporary Shipping Delays",
          "subtitle": "Due to ongoing wildfires surrounding our Los Angeles",
          "publishDate": new Date(),    // 发布时间
          "intervalTime": 86400,        // 间隔时间（单位秒）
          "popCount": 3,                // 展示次数
          "delay": 0,                    // 延迟显示时间（单位秒）
          "name": "homePagePop",       // 弹窗名称
          "subTitle": "JOIN THE WAITLIST",
          "tips": "Thank you for your interest in BNTO!  We’re excited to have you join our community.  Due to the high level of interest, we’ve created a waitlist to ensure everyone enjoys the best experience possible. ",
          "buttonTitle": "GET MY $20 OFF CODE",
          "description": "Due to ongoing wildfires surrounding our Los Angeles",
        },
        "wildfire": {
          // 2025.1.10 v1.2.8 山火警告需求
          "type": "textPop",            // 弹窗类型
          "title": "Temporary Shipping Delays",
          "description": "Due to ongoing wildfires surrounding our Los Angeles",
          "publishDate": new Date(),    // 发布时间
          "intervalTime": 86400,        // 间隔时间（单位秒）
          "popCount": 3,                // 展示次数
          "delay": 0                    // 延迟显示时间（单位秒）
        }
      }
    }
  }

  for (let i = 0; i < appInit.value.popList.priority.length; i++) {
    const popName = appInit.value.popList.priority[i]
    const popData = appInit.value.popList.data[popName]

    // 「本地存储的显示状态」
    let isStreamerVisible = getStreamerStatusLocalStorage(popName, {
      maxShowUpTimes: popData.popCount,
      intervalTime: popData.intervalTime
    })
    console.log("可见性", isStreamerVisible)

    // 有额外隐藏逻辑的横幅
    switch (popName) {
      case STREAMER_NAME.HOME_PAGE_POP:
        // 已登录且（已订阅或已暂停） 不显示
        if (isLogin.value && (userInfo.value.subStatus === SUB_STATUS.SUBSCRIBED || userInfo.value.subStatus === SUB_STATUS.PAUSED)) isStreamerVisible = false
        break;
      case STREAMER_NAME.WAITLIST_POP:
        // 已登录 不显示
        if (isLogin.value) isStreamerVisible = false
        break;
      case STREAMER_NAME.INVITATION_POP:
        // 已登录 且 是会员(订阅、暂停、取消) 不显示
        if (isLogin.value && userInfo.value.isMember) isStreamerVisible = false
        break;
      default:
        // 未定义的 do nothing，遵从「本地存储的显示状态」
        // 且不能执行隐藏，因为需要支持命名未定义且可以通用显示的情况
        break;
    }
    console.log("用户状态判断后")
    console.log("可见性", isStreamerVisible)

    if (isStreamerVisible) {
      visibleStreamerName.value = popName
      visibleStreamerType.value = popData.type
      visibleStreamerDelay.value = popData.delay // 秒
      if (popData.title) visibleStreamerTitle.value = popData.title
      if (popData.subTitle) visibleStreamerSubtitle.value = popData.subTitle
      if (popData.description) visibleStreamerDescription.value = popData.description
      if (popData.img) visibleStreamerPic.value = popData.img
      if (popData.buttonTitle) visibleStreamerBtnText.value = popData.buttonTitle
      // 如果存在已显示的横幅，则退出循环
      return
    }
  }
}
const handleStreamerClose = () => {
  setStreamerStatusLocalStorage(visibleStreamerName.value)
  animateSlideUp()
}
const handleSuccess = () => {
  setStreamerStatusLocalStorage(visibleStreamerName.value, {hideForever: true})
}
const animateSlideDown = async () => {
  let streamerHeight
  await sleep(1000) // 至少延迟1秒，保证获取到准确的横幅高度
  setTimeout(() => {
    if (isMobile.value) {
      streamerHeight = streamerMobile.value.offsetHeight;  // 获取横幅的实际高度
      streamerMobile.value.classList.add('slide-down');  // 添加动画类，触发滑出效果
    } else {
      streamerHeight = streamerDesktop.value.offsetHeight;  // 获取横幅的实际高度
      streamerDesktop.value.classList.add('slide-down');  // 添加动画类，触发滑出效果
    }
    placeholder.value.style.height = streamerHeight + 'px'  // 占位符高度填充，内容区被向下推
  }, visibleStreamerDelay.value * 1000)
}

const animateSlideUp = () => {
  if (isMobile.value) {
    streamerMobile.value.classList.remove('slide-down');
    streamerMobile.value.classList.add('slide-up');
  } else {
    streamerDesktop.value.classList.remove('slide-down');
    streamerDesktop.value.classList.add('slide-up');
  }
  placeholder.value.style.height = '0px'
}
onMounted(() => {
  streamerInit()
  animateSlideDown()
})
</script>

<template>
  <div class="streamer-mobile" ref="streamerMobile" v-if="isMobile">
    <!-- 文字横幅 -->
    <module-streamer-text
      v-if="visibleStreamerType === STREAMER_TYPE.TEXT"
      :title="visibleStreamerTitle"
      :description="visibleStreamerDescription"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
    <!-- 自定义横幅 -->
    <template v-if="visibleStreamerType === STREAMER_TYPE.CUSTOM">
      <!-- 优惠券横幅 -->
      <module-streamer-coupon
        v-if="visibleStreamerName === STREAMER_NAME.HOME_PAGE_POP"
        :title="visibleStreamerTitle"
        :subtitle="visibleStreamerSubtitle"
        :tips="visibleStreamerDescription"
        @close="handleStreamerClose"
        @success="handleSuccess"
      />
      <!-- waitlist 横幅 -->
      <module-streamer-waitlist
        v-if="visibleStreamerName === STREAMER_NAME.WAITLIST_POP"
        :title="visibleStreamerTitle"
        :subtitle="visibleStreamerSubtitle"
        :desc="visibleStreamerDescription"
        @close="handleStreamerClose"
        @success="handleSuccess"
      />
      <!-- 邀请横幅 -->
      <module-streamer-invitation
        v-if="visibleStreamerName === STREAMER_NAME.INVITATION_POP"
        :title="visibleStreamerTitle"
        :subtitle="visibleStreamerSubtitle"
        :desc="visibleStreamerDescription"
        :pic="visibleStreamerPic"
        :btn-text="visibleStreamerBtnText"
        @close="handleStreamerClose"
        @success="handleSuccess"
      />
    </template>
  </div>
  <div class="streamer-desktop" ref="streamerDesktop" v-else>
    <!-- 文字横幅 -->
    <module-streamer-text
      v-if="visibleStreamerType === STREAMER_TYPE.TEXT"
      :title="visibleStreamerTitle"
      :description="visibleStreamerDescription"
      @close="handleStreamerClose"
      @success="handleSuccess"
    />
    <!-- 自定义横幅 -->
    <template v-if="visibleStreamerType === STREAMER_TYPE.CUSTOM">
      <!-- 优惠券横幅 -->
      <module-streamer-coupon
        v-if="visibleStreamerName === STREAMER_NAME.HOME_PAGE_POP"
        :title="visibleStreamerTitle"
        :subtitle="visibleStreamerSubtitle"
        :tips="visibleStreamerDescription"
        @close="handleStreamerClose"
        @success="handleSuccess"
      />
      <!-- waitlist 横幅 -->
      <module-streamer-waitlist
        v-if="visibleStreamerName === STREAMER_NAME.WAITLIST_POP"
        :title="visibleStreamerTitle"
        :subtitle="visibleStreamerSubtitle"
        :desc="visibleStreamerDescription"
        @close="handleStreamerClose"
        @success="handleSuccess"
      />
      <!-- 邀请横幅 -->
      <module-streamer-invitation
        v-if="visibleStreamerName === STREAMER_NAME.INVITATION_POP"
        :title="visibleStreamerTitle"
        :subtitle="visibleStreamerSubtitle"
        :desc="visibleStreamerDescription"
        :pic="visibleStreamerPic"
        :btn-text="visibleStreamerBtnText"
        @close="handleStreamerClose"
        @success="handleSuccess"
      />
    </template>
  </div>
  <div class="placeholder" ref="placeholder"/>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.streamer-mobile {
  width: 100%;
  position: absolute; /* 使横幅不占据页面布局空间 */
  transform: translateY(-100%);
}

.streamer-desktop {
  width: 100%;
  position: absolute; /* 使横幅不占据页面布局空间 */
  transform: translateY(-100%);
}

.placeholder {
  height: 0;
  transition: height 0.5s ease-out;
}

.slide-down {
  transition: transform 0.5s ease-out;
  animation: slideDown 0.5s ease-out forwards; /* 触发滑动动画 */
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-up {
  transition: transform 0.5s ease-out;
  animation: slideUp 0.5s ease-out forwards; /* 触发滑动动画 */
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  99% {
    opacity: 1; // 解决收起后，页面顶部可以看到横幅的bug
  }
  100% {
    opacity: 0; // 解决收起后，页面顶部可以看到横幅的bug
    transform: translateY(-100%);
  }
}
</style>
