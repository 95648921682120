<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import {Loading} from "@element-plus/icons-vue";
import ContentMobileNumber from "~/components/account/my-profile/LoginDetails/ContentMobileNumber.vue";

const BNTOMessage = useNuxtApp().$BNTOMessage

/** data **/
const isLoading = ref(false)

const verifyStatus = ref(0)
const contentMobileNumberRef = ref(null)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isShowDrawerCartMobileNumberEdit = computed(() => useBaseStore().getterIsShowDrawerCartMobileNumberEdit)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const btnConfirmDisabled = computed(() => verifyStatus.value !== 2)

/** watch **/

/** methods **/
const handleCloseDrawerCartMobileNumberEdit = () => {
  useBaseStore().updateIsShowDrawerCartMobileNumberEdit(false)
  setTimeout(() => {
    contentMobileNumberRef.value.resetComponentData()
  }, 300)
}

const handleSave = async () => {
  if (btnConfirmDisabled.value) return
  if (isLoading.value) return
  isLoading.value = true
  await contentMobileNumberRef.value.saveMobileChange()
  isLoading.value = false
  handleCloseDrawerCartMobileNumberEdit()
}

const handleUpdateMobileNumber = (e) => {
  verifyStatus.value = e.verifyStatus
}

/** lifecycle **/

</script>

<template>
  <div class="drawer-cart-mobile-number-edit-mobile" v-if="isMobile">
    <Drawer
      :open="isShowDrawerCartMobileNumberEdit"
      :position="'right'"
      :frosted-glass="false"
      :z-index="3"
      @close="handleCloseDrawerCartMobileNumberEdit"
    >
      <div class="drawer">
        <div class="header">
          <div class="close" @click="handleCloseDrawerCartMobileNumberEdit">
            <img src="@/assets/img/icon-close-black.svg" alt="close">
          </div>

          <div class="header-top">
            mobile number
          </div>
          <div class="header-bottom">
            Please confirm mobile number
          </div>
        </div>

        <div class="body">
          <content-mobile-number ref="contentMobileNumberRef" @update="handleUpdateMobileNumber"/>
        </div>

        <div class="footer">
          <div class="btn-secondary" @click="handleCloseDrawerCartMobileNumberEdit"><span>cancel</span></div>
          <div class="btn-primary" :class="{'btn-disabled': btnConfirmDisabled || isLoading}" @click="handleSave">
            <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
              <Loading/>
            </el-icon>
            <span>save</span>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
  <div class="drawer-cart-mobile-number-edit-desktop" v-else>
    <Drawer
      :open="isShowDrawerCartMobileNumberEdit"
      :position="'right'"
      :frosted-glass="false"
      :z-index="3"
      @close="handleCloseDrawerCartMobileNumberEdit"
    >
      <div class="drawer">
        <div class="header">
          <div class="close" @click="handleCloseDrawerCartMobileNumberEdit">
            <img src="@/assets/img/icon-close-black.svg" alt="close">
          </div>

          <div class="header-top">
            mobile number
          </div>
          <div class="header-bottom">
            Please confirm mobile number
          </div>
        </div>

        <div class="body">
          <content-mobile-number ref="contentMobileNumberRef" @update="handleUpdateMobileNumber"/>
        </div>

        <div class="footer">
          <div class="btn-secondary" @click="handleCloseDrawerCartMobileNumberEdit"><span>cancel</span></div>
          <div class="btn-primary" :class="{'btn-disabled': btnConfirmDisabled || isLoading}" @click="handleSave">
            <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
              <Loading/>
            </el-icon>
            <span>save</span>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-cart-mobile-number-edit-mobile {
  .drawer {
    width: 375px;
    height: calc(100vh - $mobile-header-height);
    overflow-y: auto;
    position: relative;

    display: flex;
    flex-direction: column;

    .header {
      padding: 8px 16px;
      background-color: $color-BNTO-beige-light;
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .close {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid $color-BNTO-beige-dark;
        position: absolute;
        right: 16px;
        cursor: pointer;
      }

      .header-top {
        padding-top: 19px;
        padding-bottom: 7px;

        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
      }

      .header-bottom {
        padding-top: 12px;
        padding-bottom: 2px;

        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        border-top: 1px dashed $color-gray-400;
      }
    }

    .body {
      height: fit-content;
      padding: 16px;
      margin-bottom: 108px;
    }

    .footer {
      width: 100%;
      padding: 10px 16px 35px 16px;

      position: fixed;
      bottom: 0;

      display: flex;
      gap: 10px;

      border-top: 1px solid $color-gray-300;
      background: white;
      box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.1);

      .btn-secondary {
        width: 166.5px;
        flex-shrink: 0;
      }

      .btn-primary {
        width: 166.5px;
        flex-shrink: 0;
      }
    }
  }
}

.drawer-cart-mobile-number-edit-desktop {
  .drawer {
    width: 375px;
    height: calc(100vh - $desktop-header-height);
    overflow-y: auto;
    position: relative;

    display: flex;
    flex-direction: column;

    .header {
      padding: 8px 16px;
      background-color: $color-BNTO-beige-light;
      position: relative;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .close {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid $color-BNTO-beige-dark;
        position: absolute;
        right: 16px;
        cursor: pointer;
      }

      .header-top {
        padding-top: 19px;
        padding-bottom: 7px;

        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
      }

      .header-bottom {
        padding-top: 12px;
        padding-bottom: 2px;

        /* Text xxs/Regular */
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        border-top: 1px dashed $color-gray-400;
      }
    }

    .body {
      height: fit-content;
      padding: 16px;
      margin-bottom: 108px;
    }

    .footer {
      width: 100%;
      padding: 10px 16px 35px 16px;

      position: fixed;
      bottom: 0;

      display: flex;
      gap: 10px;

      border-top: 1px solid $color-gray-300;
      background: white;
      box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.1);

      .btn-secondary {
        width: 166.5px;
        flex-shrink: 0;
      }

      .btn-primary {
        width: 166.5px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
