// plugins/hooksLogger.js
import {
  smartLookElementClickObserve,
  smartLookElementClickObserveAsync,
  smartLookElementExposeObserve,
  smartLookElementExposeObserveAsync,
  smartLookTrackPageView
} from "~/utils/smartlook";
import _ from "lodash"

const debounce = _.debounce((params, func) => {
  func(params);
}, 300);

export default defineNuxtPlugin(nuxtApp => {
  // App Hooks
  // nuxtApp.hook('app:created', (app) => {
  //   console.log('app:created', app);
  // });
  // nuxtApp.hook('app:beforeMount', (app) => {
  //   console.log('app:beforeMount', app);
  // });
  // nuxtApp.hook('app:mounted', (app) => {
  //   console.log('app:mounted', app);
  // });
  // nuxtApp.hook('app:rendered', (ctx) => {
  //   console.log('app:rendered', ctx);
  // });
  // nuxtApp.hook('app:redirected', () => {
  //   console.log('app:redirected');
  // });
  // nuxtApp.hook('app:suspense:resolve', (Component) => {
  //   console.log('app:suspense:resolve', Component);
  // });
  // nuxtApp.hook('app:error', (err) => {
  //   console.log('app:error', err);
  // });
  // nuxtApp.hook('app:error:cleared', (options) => {
  //   console.log('app:error:cleared', options);
  // });
  // nuxtApp.hook('app:chunkError', (options) => {
  //   console.log('app:chunkError', options);
  // });
  // nuxtApp.hook('app:data:refresh', (keys) => {
  //   console.log('app:data:refresh', keys);
  // });
  // nuxtApp.hook('app:manifest:update', (meta) => {
  //   console.log('app:manifest:update', meta);
  // });
  //
  // // Dev Hooks
  // nuxtApp.hook('dev:ssr-logs', (logs) => {
  //   console.log('dev:ssr-logs', logs);
  // });
  //
  // // Link Prefetch Hook
  // nuxtApp.hook('link:prefetch', (link) => {
  //   console.log('link:prefetch', link);
  // });
  //
  // // Page Hooks
  // nuxtApp.hook('page:start', (Component) => {
  //   console.log('page:start', Component);
  // });
  nuxtApp.hook('page:finish', (Component) => {
    console.log('page:finish', Component);

    // 启用初始的SmartLook事件监听
    smartLookTrackPageView()
    smartLookElementExposeObserve()
    smartLookElementClickObserve()

    // 启用MutationObserver来监测后续加载的内容
    const domObserver = new MutationObserver(() => {
      // debounce避免高频重复监听
      debounce({}, () => {
        console.log('Dom Mutation Observed');
        // 对异步加载的元素重新应用监听
        smartLookElementExposeObserveAsync()
        smartLookElementClickObserveAsync()
      })
    });

    // 监听整个body的子元素变化
    domObserver.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
  // nuxtApp.hook('page:transition:start', () => {
  //   console.log('page:transition:start');
  // });
  // nuxtApp.hook('page:transition:finish', (Component) => {
  //   console.log('page:transition:finish', Component);
  // });
  // nuxtApp.hook('page:view-transition:start', (transition) => {
  //   console.log('page:view-transition:start', transition);
  // });
  // nuxtApp.hook('page:loading:start', () => {
  //   console.log('page:loading:start');
  // });
  // nuxtApp.hook('page:loading:end', () => {
  //   console.log('page:loading:end');
  // });
  //
  // // Vue Hooks
  // nuxtApp.hook('vue:setup', () => {
  //   console.log('vue:setup');
  // });
  // nuxtApp.hook('vue:error', (...args) => {
  //   console.log('vue:error', args);
  // });
});
