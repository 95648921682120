import {
  PATH_ACCOUNT,
  PATH_ACCOUNT_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_BILLING_AND_RECEIPTS,
  PATH_ACCOUNT_MY_SUBSCRIPTION,
  PATH_ACCOUNT_RENTAL_HISTORY,
  PATH_HOME,
  PATH_LOGIN,
  PATH_REGISTER
} from "~/utils/constants";
import {closeAllDrawers} from "~/utils/common";
import {googleTrackPageView} from "~/utils/google-track";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    // google 埋点，pageView
    googleTrackPageView(to.name, to.fullPath)
    // 路由跳转时，统一关闭弹窗
    closeAllDrawers()
  }

  const isLogin = useBaseStore().getterIsLogin
  const userInfo = useBaseStore().getterUserInfo

  if (isLogin && to.path === PATH_LOGIN) {
    return navigateTo(PATH_HOME)
  }

  if (isLogin && to.path === PATH_REGISTER) {
    return navigateTo(PATH_ACCOUNT_ACCOUNT_OVERVIEW)
  }

  if (isLogin && to.path === PATH_ACCOUNT) {
    return navigateTo(PATH_ACCOUNT_ACCOUNT_OVERVIEW)
  }

  /**
   * 需要用到userInfo的，必须要在04.dynamic.js中，将用户信息请求的返回，阻塞页面流程
   * 拿到用户信息后再进入路由
   * **/
  if (isLogin && userInfo.isMember === 1 && userInfo.subStatus !== SUB_STATUS.CANCELLED && to.path === PATH_SUBSCRIBE) {
    return navigateTo(PATH_ACCOUNT_ACCOUNT_OVERVIEW)
  }

  // 非会员不能访问 租赁历史、我的订阅、权益
  if (isLogin && userInfo.isMember === 0) {
    switch (to.path) {
      case PATH_ACCOUNT_RENTAL_HISTORY:
      case PATH_ACCOUNT_MY_SUBSCRIPTION:
      case PATH_ACCOUNT_BILLING_AND_RECEIPTS:
        return navigateTo(PATH_ACCOUNT_ACCOUNT_OVERVIEW)
    }
  }
})
