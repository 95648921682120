<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleInput from "~/components/ModuleInput.vue";
import {phoneValid, timeCountdown} from "~/utils/common";
import {MOBILE_ERROR_TIPS_MAP} from "~/utils/constants";
import {mobileCheckVerifyCode, mobileSendVerifyCode} from "~/api/api.mobile";
import {userModifyMobile} from "~/api/api.user";

const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits(["update"])

/** props **/
const props = defineProps({})

let timer = null

/** data **/
// 电话
const mobile = ref("")
// mobile.value.replace(/[()\-\s]/g, '') 原电话
const mobileError = ref(false)
const mobileErrorTips = ref("")

const code = ref("")
const verifyStatus = ref(0) // 0 未校验 1 校验中 2 校验成功 3 校验失败
const codeErrorTips = ref("")

const waitingTime = 60
const codeTimeCountdown = ref(waitingTime)

const btnSendCodeText = ref("send code")
const btnSendCodeDisabled = ref(true)

const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** watch **/
watch(mobile, () => {
  code.value = ""
  resetCodeInputError()
  if (codeTimeCountdown.value !== waitingTime) return
  btnSendCodeDisabled.value = !phoneValid(mobile.value);

  emits("update", {mobile: mobile.value, code: code.value, verifyStatus: verifyStatus.value})
})
watch(code, async () => {
  resetCodeInputError()
  emits("update", {mobile: mobile.value, code: code.value, verifyStatus: verifyStatus.value})
  if (code.value.length === 6) {
    verifyStatus.value = 1

    try {
      await mobileCheckVerifyCode({mobile: mobile.value.replace(/[()\-\s]/g, ''), verifyCode: code.value})
      verifyStatus.value = 2
      emits("update", {mobile: mobile.value, code: code.value, verifyStatus: verifyStatus.value})
    } catch (e) {
      verifyStatus.value = 3
      codeErrorTips.value = e.message.content
      emits("update", {mobile: mobile.value, code: code.value, verifyStatus: verifyStatus.value})
    }

  }
})

/** methods **/
const handleMobileInput = () => {
  let input = mobile.value.replace(/\D/g, ''); // 移除所有非数字字符
  if (input.startsWith("1")) input = input.slice(1) // 第一位不可以是1
  if (input.length > 0) {
    input = '(' + input;
  }
  if (input.length > 4) {
    input = input.slice(0, 4) + ') ' + input.slice(4);
  }
  if (input.length > 9) {
    input = input.slice(0, 9) + '-' + input.slice(9, 13);
  }
  mobile.value = input.slice(0, 14); // 限制最大长度为14个字符
}
const handleMobileBlur = () => {
  // reset
  mobileError.value = false
  mobileErrorTips.value = ""
  // 必填校验
  if (!mobile.value) {
    mobileError.value = true
    mobileErrorTips.value = MOBILE_ERROR_TIPS_MAP.REQUIRED
    return
  }
  // 合法校验
  const valid = phoneValid(mobile.value)
  if (!valid) {
    mobileError.value = true
    mobileErrorTips.value = MOBILE_ERROR_TIPS_MAP.FORMAT_ERROR
  }
}
const handleSendCodeClick = async () => {
  if (btnSendCodeDisabled.value) return
  btnSendCodeDisabled.value = true

  try {
    await mobileSendVerifyCode({mobile: mobile.value.replace(/[()\-\s]/g, ''), businessType: "userInfo"})
    btnSendCodeText.value = "resend"
    timeCountdown(waitingTime, (time, t) => {
      timer = t
      codeTimeCountdown.value = time
      if (time === 0) {
        setTimeout(() => {
          btnSendCodeDisabled.value = false
          codeTimeCountdown.value = waitingTime
        }, 1000)
      }
    });
  } catch (e) {
    btnSendCodeDisabled.value = false
    console.error(e)
  }
}
const resetCodeInputError = () => {
  // reset
  codeErrorTips.value = ""
  verifyStatus.value = 0
}
const resetComponentData = () => {
  mobile.value = ""
  mobileError.value = false
  mobileErrorTips.value = ""

  code.value = ""
  verifyStatus.value = 0 // 0 未校验 1 校验中 2 校验成功 3 校验失败
  codeErrorTips.value = ""

  codeTimeCountdown.value = waitingTime

  btnSendCodeText.value = "send code"
  btnSendCodeDisabled.value = true

  clearInterval(timer)
}
const saveMobileChange = async () => {
  if (isLoading.value) return
  isLoading.value = true
  try {
    await userModifyMobile({mobile: mobile.value.replace(/[()\-\s]/g, ''), verifyCode: code.value})
    useBaseStore().updateUserInfo({...userInfo.value, mobile: mobile.value})
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
  }
}

/** expose **/
defineExpose({
  resetComponentData,
  saveMobileChange,
})

/** lifecycle **/

</script>

<template>
  <div class="content-mobile-number-mobile" v-if="isMobile">
    <div class="title">Mobile Number (U.S. Only)</div>
    <div class="desc">After entering and verifying your mobile number, our operations team will be able to contact you
      directly.
    </div>
    <div class="mobile-input-container">
      <module-input
        :container-class="'mobile-input'"
        type="tel"
        v-model="mobile"
        name="phone_number"
        max-length="14"
        :error-tips="mobileErrorTips"
        :error="mobileError"
        @input="handleMobileInput"
        @blur="handleMobileBlur"
      />
      <div class="right">
        <div
          v-if="verifyStatus !== 2"
          class="btn-secondary"
          :class="{'btn-disabled-secondary': btnSendCodeDisabled}"
          @click="handleSendCodeClick"
        >
          <span>{{ btnSendCodeText }}</span>
          <span v-if="codeTimeCountdown !== waitingTime">({{ codeTimeCountdown }})</span>
        </div>
        <div v-if="verifyStatus === 2" class="btn-secondary verified">
          <span>verified</span>
          <img src="@/assets/img/icon-verified.svg" alt="success">
        </div>
      </div>
    </div>
    <module-input
      style="margin-top: 10px"
      type="code"
      :title="'Verification Code'"
      v-model="code"
      :error="verifyStatus === 3"
      :error-tips="codeErrorTips"
      :success="verifyStatus === 2"
    />
  </div>
  <div class="content-mobile-number-desktop" v-else>
    <div class="title">Mobile Number (U.S. Only)</div>
    <div class="desc">After entering and verifying your mobile number, our operations team will be able to contact you
      directly.
    </div>
    <div class="mobile-input-container">
      <module-input
        :container-class="'mobile-input'"
        type="tel"
        v-model="mobile"
        name="phone_number"
        max-length="14"
        :error-tips="mobileErrorTips"
        :error="mobileError"
        @input="handleMobileInput"
        @blur="handleMobileBlur"
      />
      <div class="right">
        <div
          v-if="verifyStatus !== 2"
          class="btn-secondary"
          :class="{'btn-disabled-secondary': btnSendCodeDisabled}"
          @click="handleSendCodeClick"
        >
          <span>{{ btnSendCodeText }}</span>
          <span v-if="codeTimeCountdown !== waitingTime">({{ codeTimeCountdown }})</span>
        </div>
        <div v-if="verifyStatus === 2" class="btn-secondary verified">
          <span>verified</span>
          <img src="@/assets/img/icon-verified.svg" alt="success">
        </div>
      </div>
    </div>
    <module-input
      style="margin-top: 10px"
      type="code"
      :title="'Verification Code'"
      v-model="code"
      :error="verifyStatus === 3"
      :error-tips="codeErrorTips"
      :success="verifyStatus === 2"
    />
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.content-mobile-number-mobile {
  width: 100%;
  margin: 0 auto;

  .title {
    /* Text sm/Bold */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: 20px; /* 142.857% */
    margin-bottom: 6px;
  }

  .desc {
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 166.667% */
    margin-bottom: 6px;
  }

  .mobile-input-container {
    display: flex;

    .mobile-input {
      z-index: 1;
    }

    .right {

      .btn-secondary {
        width: 98px;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;

        min-height: 36px;
        padding: 0 8px;
      }

      .verified {
        width: 98px;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;

        min-height: 36px;
        padding: 0 8px;

        color: $color-BNTO-green;

        cursor: default;

        &:active {
          img {
            opacity: 1;
          }
        }
      }
    }
  }
}

.content-mobile-number-desktop {
  width: 100%;
  margin: 0 auto;

  .title {
    /* Text sm/Bold */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: 20px; /* 142.857% */
    margin-bottom: 6px;
  }

  .desc {
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 166.667% */
    margin-bottom: 6px;
  }

  .mobile-input-container {
    display: flex;

    .mobile-input {
      z-index: 1;
    }

    .right {

      .btn-secondary {
        width: 98px;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;

        min-height: 36px;
        padding: 0 8px;
      }

      .verified {
        width: 98px;

        /* Button/small */
        font-family: "Druk Wide Cy";
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 111.111% */
        text-transform: uppercase;

        min-height: 36px;
        padding: 0 8px;

        color: $color-BNTO-green;

        cursor: default;

        &:active {
          img {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
