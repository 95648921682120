import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function userSignUp({name, email, password, confirmPassword, emailCode}) {
  return omniFetch({
    url: config.v1Path + config.userSignUpPath,
    method: "post",
    params: {
      name,
      email,
      password,
      confirmPassword,
      emailCode,
    }
  })
}

export function userRentalHistory() {
  return omniFetch({
    url: config.v1Path + config.userRentalHistoryPath,
    method: "post",
    params: {}
  })
}

export function userLogin({email, password}) {
  return omniFetch({
    url: config.v1Path + config.userLoginPath,
    method: "post",
    params: {email, password}
  })
}

export function userResetPassword({email, password, confirmPassword, emailCode}) {
  return omniFetch({
    url: config.v1Path + config.userResetPasswordPath,
    method: "post",
    params: {email, password, confirmPassword, emailCode}
  })
}

export function userLogout() {
  return omniFetch({
    url: config.v1Path + config.userLogoutPath,
    method: "post",
    params: {}
  })
}

export function userDelete() {
  return omniFetch({
    url: config.v1Path + config.userDeletePath,
    method: "post",
    params: {}
  })
}

export function userModifyMobile({mobile, verifyCode}) {
  return omniFetch({
    url: config.v1Path + config.userModifyMobilePath,
    method: "post",
    params: {mobile, verifyCode}
  })
}

export function userSaveStockNotification({email, skuIdList}) {
  return omniFetch({
    url: config.v1Path + config.userSaveStockNotificationPath,
    method: "post",
    params: {email, skuIdList} // "skuIdList":["sku1","sku2","sku3"]
  })
}

export function userLoginThird({thirdToken, platform}) {
  return omniFetch({
    url: config.v1Path + config.userLoginThirdPath,
    method: "post",
    params: {thirdToken, platform} // apple\facebook\google
  })
}

export function userLoginMethodList() {
  return omniFetch({
    url: config.v1Path + config.userLoginMethodListPath,
    method: "post",
    params: {}
  })
}

export function userLoginThirdConfirm() {
  return omniFetch({
    url: config.v1Path + config.userLoginThirdConfirmPath,
    method: "post",
    params: {}
  })
}

export function userLoginThirdSetPassword({email, password, confirmPassword}) {
  return omniFetch({
    url: config.v1Path + config.userLoginThirdSetPasswordPath,
    method: "post",
    params: {email, password, confirmPassword}
  })
}
